import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// icons
import { FailedIcon } from "../../../assets/icons/FailedIcon";
import { SuccessIcon } from "../../../assets/icons/SuccessIcon";

// hooks
import { useHeaderFetch } from "../../../components/header/use-header";
import { usePreviewModalFetch } from "../../../components/preview-cart-modal/use-preview-modal";

// consts
import { PATHS } from "../../../route/route.controls";

// components
import Button from "../../../components/button/Button";
import { CartModal } from "../../../components/cart-modal/CartModal";

// styles
import styles from "./PaymentStatus.module.scss";
import { CART } from "../../../context/cart-provider/CartProvider.consts";

//translation
import { Trans } from "@lingui/macro";

export function PaymentStatus() {
  const navigate = useNavigate();
  const location = useLocation();

  const [isOpenCartModal, setIsOpenCartModal] = useState(false);

  const { isOpenPreviewCart } = useHeaderFetch();

  const { filteredCart, totalPrice } = usePreviewModalFetch(isOpenPreviewCart);

  const isSuccessPath = location.pathname.includes("success");

  useEffect(() => {
    if (isSuccessPath) {
      localStorage.removeItem(CART);
    }
  }, [location.pathname]);

  return (
    <section className={styles.section}>
      {isSuccessPath ? (
        <>
          <div className={styles.mainContent}>
            <div className={`${styles.iconWrapper} ${styles.successIconBg}`}>
              <SuccessIcon />
            </div>
            <p className={styles.title}>
              <Trans>Payment successful!</Trans>
            </p>
            <p className={styles.description}>
              <Trans>
                Thank you for your payment. You can find your purchases in My
                courses page.
              </Trans>
            </p>
          </div>
          <div className={styles.buttonsWrapper}>
            <Button onClick={() => navigate(PATHS.myCourses)} width="auto">
              <Trans>My courses</Trans>
            </Button>
            <Button
              onClick={() => navigate(PATHS.index)}
              variant="outline"
              width="auto">
              <Trans>Back to main</Trans>
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className={styles.mainContent}>
            <div className={`${styles.iconWrapper} ${styles.failedIconBg}`}>
              <FailedIcon />
            </div>
            <p className={styles.title}>
              <Trans>Payment Failed</Trans>
            </p>
            <p className={styles.description}>
              <Trans>
                Something went wrong, we couldn’t process your payment. Contact
                our support if you have lost money.
              </Trans>
            </p>
          </div>
          <div className={styles.buttonsWrapper}>
            <Button onClick={() => setIsOpenCartModal(true)} width="auto">
              <Trans>Retry payment</Trans>
            </Button>
            <Button
              onClick={() => navigate(PATHS.index)}
              variant="outline"
              width="auto">
              <Trans>Back to main</Trans>
            </Button>
          </div>
        </>
      )}

      <CartModal
        isOpen={isOpenCartModal}
        onClose={() => setIsOpenCartModal(false)}
        cartProducts={filteredCart}
        total={totalPrice}
      />
    </section>
  );
}
